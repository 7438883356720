.ButtonBase-module_buttonBase__1pgYp {
  font: inherit;
  color: inherit;
}

.Image-module_imageBase__FcqAo {
}

.VideoPlayerModal-module_closeButtonContainer__3x7e4 {
  top: 10px;
  right: 10px;
}
  @media (min-width: 768px) {.VideoPlayerModal-module_closeButtonContainer__3x7e4 {
    top: 27px;
    right: 34px
}
  }

[aria-label='video_player-dialog'] > div {
  position: static !important;
}

.VideoPlayerModal-module_customPlayIcon__Wcl78 {
  width: 80px !important;
  height: 101px !important;
  position: relative;
}

.VideoPlayerModal-module_video_container__22KA5 .VideoPlayerModal-module_playIconHover__3S2Oq {
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
}

.VideoPlayerModal-module_video_container__22KA5 .VideoPlayerModal-module_playIcon__xzprx {
  position: absolute;
  transition: all 0.3s ease;
}

.VideoPlayerModal-module_video_container__22KA5:hover .VideoPlayerModal-module_playIcon__xzprx {
  visibility: hidden;
  opacity: 0;
}

.VideoPlayerModal-module_video_container__22KA5:hover .VideoPlayerModal-module_playIconHover__3S2Oq {
  visibility: visible;
  opacity: 1;
}

.MediaGallery-module_previewItem__1ViCU {
  aspect-ratio: 1/1;
}

.apple-login-module_button__1DGhc:hover,
.apple-login-module_button__1DGhc:focus,
.apple-login-module_button__1DGhc:active {
  box-shadow: inset 0 0 0 1px #325e77;
}

.TextInput-module_container__3q1Ev {
  height: 80px;
}

.TextInput-module_rightElement__2_ouy {
  bottom: 20px;
}

.TextInput-module_inputLabel__332dY {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: top, color;
  transition-duration: 150ms;
  top: 40px;
}

.TextInput-module_lifted__1bfg_,
.TextInput-module_focused__2Y7E5 {
  top: 0px;
  font-weight: 600;
}

.TextInput-module_filledBackground__2KYxa {
  background-color: #1e2b32;
}

.Autocomplete-module_menu__1cqc6 {
  max-height: 200px;
}

/**
 * TODO:
 * pass video styles as a tw className prop to VideoPlayer?
 * move subtitles styling to the VideoPlayer component
 */
.BelongExplainerVideo-module_video_container__2lmzI video {
  border: 5px solid #323239;
  border-radius: 10px;
  box-shadow: 0px 14px 20px 4px rgba(0, 0, 0, 0.2);
  background-color: #323239;
}

.BelongExplainerVideo-module_video_container__2lmzI video::cue {
  text-shadow: 0 0 0.2rem black, 0 0 0.4rem black;
  background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 600px) {

.BelongExplainerVideo-module_video_container__2lmzI video::cue {
    font-size: 1.2rem;
    font-weight: 600
}
  }

.BelongProductLogo-module_gradientText__1DPnI {
  background: rgb(50, 94, 119);
  background: linear-gradient(45deg, rgba(50, 94, 119, 1) 0%, rgba(62, 228, 169, 1) 57%);

  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.Bubble-module_defaultRadius__3W_Tn {
  border-radius: 20px;
}

.Bubble-module_bubble__h4znu::before {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
}

.Bubble-module_bubble__h4znu::after {
  content: '';
  position: absolute;
  height: 0px;
  width: 0px;
}

.Bubble-module_bottom__72abw::before {
  border-left: 22px solid #d6e1e4;
  border-bottom: 18px solid transparent;
  right: 50%;
  bottom: -18px;
}

.Bubble-module_bottom__72abw::after {
  border-left: 21px solid #fafbfc;
  border-bottom: 16px solid transparent;
  right: 50%;
  bottom: -15px;
}

.Bubble-module_bottomRight__3J8uv::before {
  border-left: 22px solid #d6e1e4;
  border-bottom: 18px solid transparent;
  right: 20%;
  bottom: -18px;
}

.Bubble-module_bottomRight__3J8uv::after {
  border-left: 21px solid #fafbfc;
  border-bottom: 16px solid transparent;
  right: 20%;
  bottom: -15px;
}

.Bubble-module_bottomLeft__2AWAV::before {
  border-left: 22px solid #d6e1e4;
  border-bottom: 18px solid transparent;
  right: 80%;
  bottom: -18px;
}

.Bubble-module_bottomLeft__2AWAV::after {
  border-left: 21px solid #fafbfc;
  border-bottom: 16px solid transparent;
  right: 80%;
  bottom: -15px;
}

.Bubble-module_topRight__2x0Am::before {
  border-left: 22px solid #d6e1e4;
  border-top: 18px solid transparent;
  right: 20%;
  top: -18px;
}

.Bubble-module_topRight__2x0Am::after {
  border-left: 21px solid #fafbfc;
  border-top: 16px solid transparent;
  right: 20%;
  top: -15px;
}

.Bubble-module_topLeft__3pVsQ::before {
  border-left: 22px solid #d6e1e4;
  border-top: 18px solid transparent;
  right: 85%;
  top: -18px;
}

.Bubble-module_topLeft__3pVsQ::after {
  border-left: 21px solid #fafbfc;
  border-top: 17px solid transparent;
  right: 85%;
  top: -16px;
}

.Bubble-module_left__3rIhw::before {
  border-left: 14px solid transparent;
  border-bottom: 13px solid #d6e1e4;
  left: -14px;
  top: 50%;
}

.Bubble-module_left__3rIhw::after {
  border-left: 13px solid transparent;
  border-bottom: 12px solid #fafbfc;
  left: -13px;
  top: 50.5%;
}

.Bubble-module_leftTop__1sRmR::before {
  border-left: 14px solid transparent;
  border-bottom: 13px solid #d6e1e4;
  left: -14px;
  top: 20%;
  transform: rotate(-90deg);
}

.Bubble-module_leftTop__1sRmR::after {
  border-left: 13px solid transparent;
  border-bottom: 12px solid #fafbfc;
  left: -12px;
  top: 20.7%;
  transform: rotate(-90deg);
}

.Bubble-module_leftBottom__3c30M::before {
  border-left: 14px solid transparent;
  border-bottom: 13px solid #d6e1e4;
  left: -14px;
  top: 65%;
}

.Bubble-module_leftBottom__3c30M::after {
  border-left: 13px solid transparent;
  border-bottom: 12px solid #fafbfc;
  left: -12px;
  top: 65.2%;
}

.Bubble-module_right__1dHwk::before {
  border-right: 14px solid transparent;
  border-bottom: 13px solid #d6e1e4;
  right: -14px;
  top: 50%;
}

.Bubble-module_right__1dHwk::after {
  border-right: 13px solid transparent;
  border-bottom: 12px solid #fafbfc;
  right: -13px;
  top: 50.5%;
}

.Bubble-module_rightTop__228sI::before {
  border-right: 14px solid transparent;
  border-bottom: 13px solid #d6e1e4;
  right: -13px;
  top: 15%;
  transform: rotate(90deg);
}

.Bubble-module_rightTop__228sI::after {
  border-right: 13px solid transparent;
  border-bottom: 12px solid #fafbfc;
  right: -12px;
  top: 15.5%;
  transform: rotate(90deg);
}

.Bubble-module_rightBottom__1CqtR::before {
  border-right: 14px solid transparent;
  border-bottom: 13px solid #d6e1e4;
  right: -14px;
  top: 75%;
}

.Bubble-module_rightBottom__1CqtR::after {
  border-right: 13px solid transparent;
  border-bottom: 12px solid #fafbfc;
  right: -13px;
  top: 75.5%;
}

.ButtonSelector-module_tagMarginTop__m3kPn {
  margin-top: -17px;
}

.button-selector-item-module_heightExtraSmall__3bn_M {
  height: 56px !important;
}

.button-selector-item-module_heightMobile__1OJYz {
  height: 80px;
}

@media (min-width: 768px) {
  .button-selector-item-module_heightSmall__3tLAZ {
    height: 110px;
  }

  .button-selector-item-module_heightLarge__2DBoD {
    height: 200px;
  }
}

.button-selector-item-module_widthSmall__vDlmF {
  width: 175px;
}

.button-selector-item-module_extraLarge__3wzxL {
  height: 120px;
}

@media (min-width: 768px) {

.button-selector-item-module_extraLarge__3wzxL {
    height: 230px
}
  }

@media (min-width: 768px) {
  .Carousel-module_emblaCarousel__1uwas:before,
  .Carousel-module_emblaCarousel__1uwas:after {
    content: '';
    position: absolute;
    z-index: 1;
    width: 20px;
    top: 0;
    height: 100%;
    pointer-events: none; /*makes the linkes behind clickable.*/
  }

  .Carousel-module_emblaCarousel__1uwas:before {
    left: 0px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  .Carousel-module_emblaCarousel__1uwas:after {
    right: 0px;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
}

.Checkbox-module_wrapper__TP9K5 {
  width: -moz-fit-content;
  width: fit-content;
}

@keyframes Collapsible-module_open__pGi4m {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes Collapsible-module_close__3CDiH {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.Collapsible-module_content__zw_0V {
  overflow: hidden;
}

.Collapsible-module_content__zw_0V[data-state='open'] {
    animation: Collapsible-module_open__pGi4m 300ms ease-out;
  }

.Collapsible-module_content__zw_0V[data-state='closed'] {
    animation: Collapsible-module_close__3CDiH 300ms ease-out;
  }

.DragScrollContainer-module_scrollContainer__Slq0p {
  overflow: auto;
}
  .DragScrollContainer-module_scrollContainer__Slq0p.DragScrollContainer-module_dragging__9n_9D {
    scroll-behavior: auto !important;
  }
  .DragScrollContainer-module_scrollContainer__Slq0p.DragScrollContainer-module_dragging__9n_9D > * {
      pointer-events: none;
      cursor: grab;
    }
  .DragScrollContainer-module_scrollContainer__Slq0p.DragScrollContainer-module_hideScrollbars__1ARAg {
    overflow: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .DragScrollContainer-module_scrollContainer__Slq0p.DragScrollContainer-module_hideScrollbars__1ARAg::-webkit-scrollbar {
      display: none !important;
      height: 0 !important;
      width: 0 !important;
      background: transparent !important;
      -webkit-appearance: none !important;
    }
  .DragScrollContainer-module_scrollContainer__Slq0p.DragScrollContainer-module_nativeScroll__31A4l {
    overflow: auto;
  }

.DragScrollContainer-module_dragging__9n_9D {
  cursor: grab;
}

.Dropdown-module_dropdownContainer__k77Fz .Dropdown-module_inputLabel__1I405 {
    --tw-text-opacity: 1;
    color: rgb(142 160 164 / var(--tw-text-opacity));
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: top, color;
    transition-duration: 150ms;
    top: 40px;
}
  .Dropdown-module_dropdownContainer__k77Fz [data-reach-listbox-button] {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding-left: 0;
    border: none;
    outline: none !important;
  }
  .Dropdown-module_dropdownContainer__k77Fz [data-reach-listbox-popover] {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    top: calc(100% + 2px);
    outline: none !important;
    z-index: 1;
  }
  .Dropdown-module_dropdownContainer__k77Fz .Dropdown-module_customRounded__6ls25 [data-reach-listbox-popover] {
    border-radius: 8px;
}
  .Dropdown-module_dropdownContainer__k77Fz [data-reach-listbox-option] {
    padding-left: 10px;
    margin-bottom: 10px;
}
  .Dropdown-module_dropdownContainer__k77Fz [data-reach-listbox-option]:last-child {
    margin-bottom: 0px;
}
  .Dropdown-module_dropdownContainer__k77Fz [data-reach-listbox-option][data-current-nav] {
    --tw-text-opacity: 1;
    color: rgb(50 94 119 / var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(250 251 252 / var(--tw-bg-opacity));
}

.FacebookLogin-module_icon__-bYbK {
  bottom: 8px;
}

.FacebookLogin-module_button__24sXX:hover,
.FacebookLogin-module_button__24sXX:focus,
.FacebookLogin-module_button__24sXX:active {
  box-shadow: inset 0 0 0 1px #325e77;
}

.LinkBase-module_linkBase__6HZT3 {
  font: inherit;
  color: inherit;
  text-decoration: inherit;
}

.Footer-module_footerTopGrid__Nikgc {
  grid-auto-rows: auto;
}

/* TODO: For some reason, directives not working on belong-web and no time to find out, fix soon. */
@media (min-width: 992px) {
  .Footer-module_footerTopGrid__Nikgc {
    grid-auto-rows: initial;
    grid-template-columns: 1fr auto auto auto auto;
  }
}

.Footer-module_footerBottomGrid__3Ugx_ {
  grid-auto-rows: auto;
}

@media (min-width: 768px) {

.Footer-module_footerBottomGrid__3Ugx_ {
    grid-auto-rows: initial;
    grid-template-columns: 1fr 1fr
}
  }

.Footer-module_imageLinkWithHoverContainer__3Ysgh:hover .Footer-module_linkOnHoverState__2arn0,
.Footer-module_imageLinkWithHoverContainer__3Ysgh .Footer-module_linkOnDefaultState__nJLjb {
  visibility: visible !important;
  height: auto !important;
  width: 200px !important;
}

.Footer-module_imageLinkWithHoverContainer__3Ysgh .Footer-module_linkOnHoverState__2arn0,
.Footer-module_imageLinkWithHoverContainer__3Ysgh:hover .Footer-module_linkOnDefaultState__nJLjb {
  visibility: hidden !important;
  height: 0 !important;
  width: 200px !important;
}

@media (min-width: 640px) {
  .Footer-module_imageLinkWithHoverContainer__3Ysgh .Footer-module_linkOnHoverState__2arn0,
  .Footer-module_imageLinkWithHoverContainer__3Ysgh .Footer-module_linkOnDefaultState__nJLjb,
  .Footer-module_imageLinkWithHoverContainer__3Ysgh:hover .Footer-module_linkOnHoverState__2arn0,
  .Footer-module_imageLinkWithHoverContainer__3Ysgh:hover .Footer-module_linkOnDefaultState__nJLjb {
    width: 140px !important;
  }
}

.google-login-button-module_button__D6tzB:hover,
.google-login-button-module_button__D6tzB:focus,
.google-login-button-module_button__D6tzB:active {
  box-shadow: inset 0 0 0 1px #325e77;
}

.box-marker-module_boxMarkerMap__DHa2m {
  padding-top: 1px;
  padding-bottom: 1px;
}

.box-marker-module_boxMarkerMap__DHa2m::before {
  position: absolute;
  height: 0px;
  width: 0px;
  --tw-content: "";
  content: var(--tw-content);
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid rgba(50, 94, 119, 1);
  left: 50%;
  bottom: -5px;
  transform: rotate(-90deg);
}

.box-marker-module_boxMarkerMap__DHa2m:hover::before {
  border-right-color: #3ee4a9;
}

.icon-marker-module_iconMarkerContainer__2QsZy {
  width: 76px;
  top: -10px;
}

.MediaUpload-module_mediaUpload__2vRhB {
  width: 230px;
  height: 230px;
}

.MediaUpload-module_fluid__YmjmR {
  height: 220px;
  width: 100%;
}

@media (min-width: 992px) {

  .MediaUpload-module_fluid__YmjmR {
    height: 280px;
  }
}

.MultiSelectDropdown-module_dropdownContainer__2PGiM .MultiSelectDropdown-module_inputLabel__1jGZZ {
    --tw-text-opacity: 1;
    color: rgb(142 160 164 / var(--tw-text-opacity));
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: top, color;
    transition-duration: 150ms;
    top: 40px;
}
  .MultiSelectDropdown-module_dropdownContainer__2PGiM [data-reach-listbox-button] {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 46px;
    padding-left: 0;
    border: none;
    outline: none !important;
  }
  .MultiSelectDropdown-module_dropdownContainer__2PGiM [data-reach-listbox-popover] {
    width: 100%;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    top: calc(100% + 2px);
    outline: none !important;
    z-index: 1;
  }
  .MultiSelectDropdown-module_dropdownContainer__2PGiM [data-reach-listbox-option][data-current-nav] {
    --tw-text-opacity: 1;
    color: rgb(50 94 119 / var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(250 251 252 / var(--tw-bg-opacity));
}

.MultiSelectDropdown-module_multiDropdownContainer__7SYjC {
  max-width: 300px;
  min-width: 195px;
}

.MultiSelectDropdown-module_multiDropdownCurrentValue__4ZNP8 svg {
  min-width: 15px;
}

.MultiSelectDropdown-module_multiDropdownPopover__3zut_ {
  border-radius: 10px;
}

.Paginator-module_page__2zh_1 {
  min-width: 30px;
}

.Paginator-module_break__13htl {
  margin-top: -10px;
  margin-left: 10px;
  margin-right: 10px;
}

.ProgressBar-module_progressBarWrapper__YkkWW {
  width: 100%;
  height: 6px;
  border-radius: 3px;
}

.ProgressBar-module_progressBar__1MZlH {
  width: 100%;
  position: relative;
}

.ProgressBar-module_itemWrapper__1ltE4 {
  height: 6px;
  position: relative;
}

.ProgressBar-module_itemWrapper__1ltE4:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ProgressBar-module_wordWrapper__2W1_y {
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
}

.ProgressBar-module_wordWrapper__2W1_y .ProgressBar-module_active__1pBR8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
}

.ProgressBar-module_bullet__2oy_w {
  height: 4px;
  width: 4px;
  border-radius: 60px;
  position: absolute;
}

.ProgressBar-module_bullet__2oy_w .ProgressBar-module_active__1pBR8 {
  display: block;
  cursor: pointer;

  box-shadow: 0 0 0 rgba(62, 228, 169, 0.8);

  animation: ProgressBar-module_pulse__1W3w7 2s infinite;
}

.ProgressBar-module_active__1pBR8:hover {
  animation: none;
}

@keyframes ProgressBar-module_pulse__1W3w7 {
  0% {
    box-shadow: 0 0 0 0 rgba(62, 228, 169, 0.8);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(62, 228, 169, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(62, 228, 169, 0);
  }
}

.ProgressBar-module_fillToProgress__ETnMh {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 6px;
}

.ProgressBar-module_hiddenLabel__2GCM- {
  color: transparent;
}

.SentimentField-module_icon__3e5d0 {

  width: 36px
}

@media (min-width: 768px) {
  .SentimentField-module_icon__3e5d0 {

    width: 57px
  }
}

.Slider-module_sliderContainer__3ummX [data-reach-slider-input] {
    height: 10px !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Slider-module_sliderContainer__3ummX [data-reach-slider-handle] {
    width: 46px;
    height: 50px;
    --tw-bg-opacity: 1;
    background-color: rgb(50 94 119 / var(--tw-bg-opacity));
    border-style: none;
    border-radius: 8px;
  }
  .Slider-module_sliderContainer__3ummX [data-reach-slider-handle]:focus-visible {
      box-shadow: 0 0 0 3px #3ee4a9;
    }
  .Slider-module_sliderContainer__3ummX [data-reach-slider-track] {
    height: 10px !important;
    --tw-bg-opacity: 1;
    background-color: rgb(214 225 228 / var(--tw-bg-opacity));
  }
  .Slider-module_sliderContainer__3ummX [data-reach-slider-range] {
    --tw-bg-opacity: 1;
    background-color: rgb(62 228 169 / var(--tw-bg-opacity));
}
  .Slider-module_sliderContainer__3ummX.Slider-module_gradient__ycxl6 [data-reach-slider-track] {
      background: linear-gradient(270deg, #4495fd 0%, #bbec73 42.78%, #bbec73 57.5%, #ff7272 98.92%);
    }
  .Slider-module_sliderContainer__3ummX.Slider-module_gradient__ycxl6 [data-reach-slider-range] {
    background-color: transparent;
}
  .Slider-module_sliderContainer__3ummX.Slider-module_gradient-red-to-blue__OehGG [data-reach-slider-track] {
      background: linear-gradient(90deg, #ff5661 7.04%, #1890ff 100%);
    }
  .Slider-module_sliderContainer__3ummX.Slider-module_gradient-red-to-blue__OehGG [data-reach-slider-range] {
    background-color: transparent;
}
  .Slider-module_sliderContainer__3ummX.Slider-module_gradient-red-to-green-to-red__3MVt0 [data-reach-slider-track] {
      background: linear-gradient(270deg, #fd1515 2.86%, #fda315 20.94%, #3ee4a9 50.4%, #fd9121 81.92%, #fd2868 100%);
    }
  .Slider-module_sliderContainer__3ummX.Slider-module_gradient-red-to-green-to-red__3MVt0 [data-reach-slider-range] {
    background-color: transparent;
}
  .Slider-module_sliderContainer__3ummX .Slider-module_thumbStripes__2aaaJ {
    height: 14px;
    width: 3px;
  }

.Stepper-module_active__2bZyB {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(62, 228, 169, 0.8);
  animation: Stepper-module_pulse__3HgSz 2s infinite;
}

.Stepper-module_active__2bZyB:hover {
  animation: none;
}

@keyframes Stepper-module_pulse__3HgSz {
  0% {
    box-shadow: 0 0 0 0 rgba(62, 228, 169, 0.8);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(62, 228, 169, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(62, 228, 169, 0);
  }
}

.Stepper-module_indicator__TSUK6 {
  transform: translateX(2px);
}

.Stepper-module_progressBar__2mMXI {
  height: 6px;
}

.Switch-module_defaultLabel__3v0JK {

    height: 30px
}

.Switch-module_defaultWrapper__3WtO6 {

    gap: 10px
}

.Switch-module_largeWrapper__29PGG {

    gap: 20px
}

.Switch-module_defaultRoot__bJ2pw {

    height: 30px;

    width: 52px;

    padding-left: 4px;

    padding-right: 4px
}

.Switch-module_largeRoot__1jkqp {

    height: 60px;

    width: 120px;

    padding-left: 10px;

    padding-right: 10px
}

.Switch-module_defaultThumb__1aXuL {

    height: 20px;

    width: 20px
}

.Switch-module_largeThumb__1Gh1N {

    height: 45px;

    width: 45px
}

.Switch-module_defaultThumbTranslation__tiHUp[data-state="checked"] {

    --tw-translate-x: 22px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.Switch-module_largeThumbTranslation__3DlNz[data-state="checked"] {

    --tw-translate-x: 53px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.Label-module_defaultLabel__1x1iW {

    height: 30px
}

.Label-module_largeLabel__1BmMA {

    height: 60px
}

/* font-weight bold makes the layout shift a little when hovering, text-shadow doesn't */
.Item-module_tabHover__UVEbk:hover:not(.Item-module_selected__3HW98),
.Item-module_selected__3HW98 {
  text-shadow: 0.3px 0 currentColor, 0 0 currentColor, -0.3px 0 currentColor, 0 0 currentColor;
}

.List-module_dragContainer__1suzG {
  width: 100%;
  overflow-y: auto;

  /* Remove scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
} /* Firefox */

  /* Chrome and safari */
  .List-module_dragContainer__1suzG::-webkit-scrollbar {
    display: none;
  }

.Tabs-module_container__1gq2F [data-reach-tab-list],
.Tabs-module_draggableContainer__1w3VH [data-reach-tab-list] {
  background-color: transparent;
}

.Tabs-module_container__1gq2F [data-reach-tab-panel],
.Tabs-module_draggableContainer__1w3VH [data-reach-tab-panel] {
  padding-top: 20px;
}

.Tabs-module_container__1gq2F [data-reach-tab][data-selected],
.Tabs-module_draggableContainer__1w3VH [data-reach-tab][data-selected] {
  --tw-text-opacity: 1 !important;
  color: rgb(50 94 119 / var(--tw-text-opacity)) !important;
}

.Tabs-module_tab__2TbZd [data-reach-tab],
.Tabs-module_tabWithoutBlur__1LBQ- [data-reach-tab] {
  position: relative !important;
  border-radius: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: center !important;
  text-transform: capitalize !important;
}

.Tabs-module_container__1gq2F [data-reach-tab] {
  margin-left: 10px !important;
  margin-right: 10px !important;
  flex: 1 1 0% !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.Tabs-module_container__1gq2F [data-reach-tab]:first-child {
  margin-left: 0px !important;
}

.Tabs-module_draggableContainer__1w3VH [data-reach-tab] {
  margin-left: 10px !important;
  margin-right: 10px !important;
  white-space: nowrap !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.Tabs-module_tabWithoutBlur__1LBQ- {
  position: relative;
}

.TextArea-module_container__22JPC {
  min-height: 80px;
}

.TextArea-module_rightElement__23-1p {
  bottom: 20px;
}

.TextArea-module_inputLabel__3fDw5 {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: top, color;
  transition-duration: 150ms;
  top: 40px;
}

.TextArea-module_lifted__3Ubaz,
.TextArea-module_focused__3OxMv {
  top: 0px;
  font-weight: 600;
}

.Toast-module_closeIcon__U9tgH {
  margin-top: 5px;
}

.Toast-module_mainIcon__ne6UN {
  height: 50px;
  width: 50px;
}

@keyframes Toast-module_slideInDown__261fP {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {

  .Toast-module_wrapper__2gj4K {
    width: -moz-fit-content;
    width: fit-content;
    min-width: 540px;
    max-width: 730px;
  }
}

.Toast-module_wrapper__2gj4K {
  animation: Toast-module_slideInDown__261fP 0.5s;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2)) !important;
}

.VideoSideContents-module_main__2caCl {

    max-width: 2000px
}

.VideoSideContents-module_video_container__-L6_7 {

    min-width: 70%
}
